import gql from 'graphql-tag';

export const NEWSFEED_ARTICLE_FIELDS = gql`
  fragment NewsfeedArticleFields on NewsfeedArticle {
    id
    isRestricted
    validFrom
    departmentCode
    text
    title
    previewUrl
    isRead
  }
`;
