export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  /** A String-based ISO 3166-1 alpha-2 two-letter country code, e.g., 'NO'. */
  CountryCode: any;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  /** The `Decimal` scalar type represents a decimal floating-point number. */
  Decimal: any;
  /**
   * LocalDate represents a date within the calendar, with no reference to a particular time zone or time of day.
   *
   * Allowed patterns:
   * - `YYYY-MM-DD`
   *
   * Examples:
   * - `2000-01-01`
   */
  LocalDate: any;
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: any;
  /** A String-based E.164 mobile number in its globalized format (e.g., `+4799999999`). */
  MobileNumber: any;
  URL: any;
};

export type Query = {
  __typename?: 'Query';
  /** Returns the currently authenticated user. */
  me: User;
  /** Returns the user with the specified ID. */
  user?: Maybe<User>;
  allUsers?: Maybe<Array<Maybe<User>>>;
  search: Array<SearchResult>;
  myChats: Array<Chat>;
  /** Returns users matching the specified arguments. */
  users?: Maybe<UsersConnection>;
  /** Returns status messages matching the specified arguments. */
  statusMessages: Array<StatusMessage>;
  order?: Maybe<Order>;
  /** Returns orders matching the specified arguments. */
  orders?: Maybe<OrdersConnection>;
  newsfeedArticle?: Maybe<NewsfeedArticle>;
  newsfeedArticles?: Maybe<NewsfeedArticlesConnection>;
  /** Returns customers matching the specified arguments. */
  customers?: Maybe<CustomersConnection>;
  customer?: Maybe<Customer>;
  /**
   * Capacity data for the specified factory. This requires the requesting user's `access.capacity.hasAccess` to be
   * true.
   */
  factoryCapacity?: Maybe<FactoryCapacity>;
};

export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type QuerySearchArgs = {
  term: Scalars['String'];
};

export type QueryUsersArgs = {
  filter?: Maybe<UserFilterInput>;
  sort?: Maybe<Array<UserSortTermInput>>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};

export type QueryStatusMessagesArgs = {
  filter?: Maybe<StatusMessageFilterInput>;
};

export type QueryOrderArgs = {
  id: Scalars['ID'];
};

export type QueryOrdersArgs = {
  filter?: Maybe<OrderFilterInput>;
  sort?: Maybe<Array<OrderSortTermInput>>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};

export type QueryNewsfeedArticleArgs = {
  id: Scalars['ID'];
};

export type QueryNewsfeedArticlesArgs = {
  filter?: Maybe<NewsfeedArticleFilterInput>;
  sort?: Maybe<Array<NewsfeedArticleSortTermInput>>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};

export type QueryCustomersArgs = {
  filter?: Maybe<CustomerFilterInput>;
  sort?: Maybe<Array<CustomerSortTermInput>>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};

export type QueryCustomerArgs = {
  id: Scalars['ID'];
};

export type QueryFactoryCapacityArgs = {
  factory: Scalars['ID'];
};

export type Error = {
  /** An error message primarily intended for client developers. */
  message: Scalars['String'];
  /** An identifier that can be used to search for this error in server logs. */
  correlationId?: Maybe<Scalars['String']>;
};

/** Provides aggregate order capacity data for a factory or workstation. */
export type CapacityOrderSummary = {
  __typename?: 'CapacityOrderSummary';
  /** The factory this summary is associated with. */
  factory: Factory;
  /** The workstation this summary is for. Returns `null` if this summary is for a factory. */
  workstation?: Maybe<Scalars['ID']>;
  /** The year part of the year/week this summary is for. */
  year: Scalars['Int'];
  /** The week part of the year/week this summary is for. */
  week: Scalars['Int'];
  /** The aggregate values. The list contains one object per value type the user is allowed to view. */
  values: Array<CapacityOrderSummaryValues>;
  /** All the orders used in this summary. */
  orders?: Maybe<CapacityOrderSummaryOrdersConnection>;
};

/** Provides aggregate order capacity data for a factory or workstation. */
export type CapacityOrderSummaryOrdersArgs = {
  filter?: Maybe<CapacityOrderSummaryOrderFilterInput>;
  sort?: Maybe<Array<OrderSortTermInput>>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type CapacityOrderSummaryOrdersConnection = {
  __typename?: 'CapacityOrderSummaryOrdersConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<CapacityOrderSummaryOrdersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Order>>;
};

/** An edge in a connection. */
export type CapacityOrderSummaryOrdersEdge = {
  __typename?: 'CapacityOrderSummaryOrdersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Order;
};

/** Provides aggregate order capacity data for a specific summary value type. */
export type CapacityOrderSummaryValues = {
  __typename?: 'CapacityOrderSummaryValues';
  /** The type of value represented by this object's numeric fields. */
  valueType: CapacityAggregateValueType;
  /** The aggregate value for incomplete orders. Returns `null` if there are no such orders in this summary. */
  incomplete?: Maybe<Scalars['Float']>;
  /** The aggregate value for control orders. Returns `null` if there are no such orders in this summary. */
  control?: Maybe<Scalars['Float']>;
  /** The aggregate value for final orders. Returns `null` if there are no such orders in this summary. */
  final?: Maybe<Scalars['Float']>;
  /** The aggregate value for all orders regardless of type. */
  total?: Maybe<Scalars['Float']>;
};

export type CapacityUpdatedPayload = {
  __typename?: 'CapacityUpdatedPayload';
  /** The combinations of workstations/years/weeks that were updated. */
  updated: Array<CapacityUpdatedPointer>;
};

export type CapacityUpdatedPointer = {
  __typename?: 'CapacityUpdatedPointer';
  workstation: Scalars['ID'];
  year: Scalars['Int'];
  week: Scalars['Int'];
};

export type CreateFavoritePayload = {
  __typename?: 'CreateFavoritePayload';
  favorite?: Maybe<Favorite>;
  errors?: Maybe<Array<CreateFavoriteError>>;
  query: Query;
};

export type CreateStatusMessagePayload = {
  __typename?: 'CreateStatusMessagePayload';
  statusMessage?: Maybe<StatusMessage>;
  errors?: Maybe<Array<CreateStatusMessageError>>;
  query: Query;
};

export type Customer = {
  __typename?: 'Customer';
  id?: Maybe<Scalars['ID']>;
  customerNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type CustomersConnection = {
  __typename?: 'CustomersConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<CustomersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Customer>>;
};

/** An edge in a connection. */
export type CustomersEdge = {
  __typename?: 'CustomersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Customer;
};

export type DeleteFavoritePayload = {
  __typename?: 'DeleteFavoritePayload';
  errors?: Maybe<Array<DeleteFavoriteError>>;
  query: Query;
};

export type DeleteStatusMessagePayload = {
  __typename?: 'DeleteStatusMessagePayload';
  errors?: Maybe<Array<DeleteStatusMessageError>>;
  query: Query;
};

export type Factory = {
  __typename?: 'Factory';
  id: Scalars['ID'];
  /**
   * A country code for the country where this factory is located. May be `null`, for example if this is not a real
   * factory.
   */
  country?: Maybe<Scalars['CountryCode']>;
};

/** Provides capacity data for a factory. */
export type FactoryCapacity = {
  __typename?: 'FactoryCapacity';
  /** The associated factory. */
  factory: Factory;
  /** The factory's workstations. */
  workstations: Array<WorkstationCapacity>;
  /** The workstation with the specified ID. */
  workstation?: Maybe<WorkstationCapacity>;
  /** A list of weekly capacity values for the factory. */
  weeklyValues: Array<FactoryWeeklyValue>;
  /** Aggregate order data for this factory. */
  orderSummary?: Maybe<CapacityOrderSummary>;
};

/** Provides capacity data for a factory. */
export type FactoryCapacityWorkstationsArgs = {
  planningTypes: Array<CapacityPlanningType>;
};

/** Provides capacity data for a factory. */
export type FactoryCapacityWorkstationArgs = {
  workstation: Scalars['ID'];
};

/** Provides capacity data for a factory. */
export type FactoryCapacityWeeklyValuesArgs = {
  valueType: CapacityAggregateValueType;
  planningTypes: Array<CapacityPlanningType>;
  category: CapacityCategory;
  startYear: Scalars['Int'];
  startWeek: Scalars['Int'];
  numWeeks: Scalars['Int'];
};

/** Provides capacity data for a factory. */
export type FactoryCapacityOrderSummaryArgs = {
  planningTypes: Array<CapacityPlanningType>;
  category: CapacityCategory;
  year: Scalars['Int'];
  week: Scalars['Int'];
};

export type FactoryWeeklyValue = {
  __typename?: 'FactoryWeeklyValue';
  year: Scalars['Int'];
  week: Scalars['Int'];
  value: Scalars['Float'];
};

export type Favorite = {
  __typename?: 'Favorite';
  id: Scalars['ID'];
  /** The index of this favorite among its siblings. */
  index: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /**
   * The item that is marked as favorite. This can be null, for example if the item no longer exists or the user no
   * longer has access to it.
   */
  item?: Maybe<FavoriteItem>;
};

/** Indicates that no item with the specified ID was found to favorite. */
export type FavoriteItemNotFoundError = Error & {
  __typename?: 'FavoriteItemNotFoundError';
  message: Scalars['String'];
  /** An identifier that can be used to search for this error in server logs. */
  correlationId?: Maybe<Scalars['String']>;
};

/** Indicates that no favorite was found with the specified ID. */
export type FavoriteNotFoundError = Error & {
  __typename?: 'FavoriteNotFoundError';
  message: Scalars['String'];
  /** An identifier that can be used to search for this error in server logs. */
  correlationId?: Maybe<Scalars['String']>;
};

/** Indicates that the item is already marked as a favorite by the user. */
export type ItemAlreadyFavoriteError = Error & {
  __typename?: 'ItemAlreadyFavoriteError';
  message: Scalars['String'];
  /** An identifier that can be used to search for this error in server logs. */
  correlationId?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Creates and publishes a new status message. This requires the user's
   * `access.statusMessage.canManageStatusMessages` to be `true`.
   */
  createStatusMessage: CreateStatusMessagePayload;
  /** Deletes a status message. This requires the user's `access.statusMessage.canManageStatusMessages` to be `true`. */
  deleteStatusMessage: DeleteStatusMessagePayload;
  setStatusMessageIsRead: SetStatusMessageIsReadPayload;
  /** Only used to simplify internal testing. Triggers the orderUpdated subscription for the specified order. */
  testTriggerOrderUpdated: TestTriggerOrderUpdatedPayload;
  setNewsfeedArticleIsRead: SetNewsfeedArticleIsReadPayload;
  /** Marks an item as a favorite for a user. The index will be clamped to the allowed range. */
  createFavorite: CreateFavoritePayload;
  /** Sets the index of a favorite. The index will be clamped to the allowed range. */
  setFavoriteIndex: SetFavoriteIndexPayload;
  /** Removes a favorite. */
  deleteFavorite: DeleteFavoritePayload;
  /** Only used to simplify internal testing. Triggers the capacityUpdated subscription with test data. */
  testTriggerCapacityUpdated: TestTriggerCapacityUpdatedPayload;
};

export type MutationCreateStatusMessageArgs = {
  input: CreateStatusMessageInput;
};

export type MutationDeleteStatusMessageArgs = {
  input: DeleteStatusMessageInput;
};

export type MutationSetStatusMessageIsReadArgs = {
  input: SetStatusMessageIsReadInput;
};

export type MutationTestTriggerOrderUpdatedArgs = {
  input: TestTriggerOrderUpdatedInput;
};

export type MutationSetNewsfeedArticleIsReadArgs = {
  input: SetNewsfeedArticleIsReadInput;
};

export type MutationCreateFavoriteArgs = {
  input: CreateFavoriteInput;
};

export type MutationSetFavoriteIndexArgs = {
  input: SetFavoriteIndexInput;
};

export type MutationDeleteFavoriteArgs = {
  input: DeleteFavoriteInput;
};

export type NewsfeedArticle = {
  __typename?: 'NewsfeedArticle';
  id: Scalars['ID'];
  isRestricted: Scalars['Boolean'];
  validFrom?: Maybe<Scalars['LocalDate']>;
  responsibleUser?: Maybe<User>;
  departmentCode?: Maybe<Scalars['String']>;
  previewUrl?: Maybe<Scalars['URL']>;
  title: Scalars['String'];
  text: Scalars['String'];
  /** Whether the current user has marked the article as read. */
  isRead: Scalars['Boolean'];
};

/** Indicates that no newsfeed article was found with the specified ID. */
export type NewsfeedArticleNotFoundError = Error & {
  __typename?: 'NewsfeedArticleNotFoundError';
  message: Scalars['String'];
  /** An identifier that can be used to search for this error in server logs. */
  correlationId?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type NewsfeedArticlesConnection = {
  __typename?: 'NewsfeedArticlesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<NewsfeedArticlesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<NewsfeedArticle>>;
};

/** An edge in a connection. */
export type NewsfeedArticlesEdge = {
  __typename?: 'NewsfeedArticlesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: NewsfeedArticle;
};

/** Indicates that the user does not have access to the item with the specified ID. */
export type NoAccessToFavoriteItemError = Error & {
  __typename?: 'NoAccessToFavoriteItemError';
  message: Scalars['String'];
  /** An identifier that can be used to search for this error in server logs. */
  correlationId?: Maybe<Scalars['String']>;
};

/** Indicates that the user does not have access to manage status messages. */
export type NoAccessToManageStatusMessagesError = Error & {
  __typename?: 'NoAccessToManageStatusMessagesError';
  message: Scalars['String'];
  /** An identifier that can be used to search for this error in server logs. */
  correlationId?: Maybe<Scalars['String']>;
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['ID'];
  orderNumber: Scalars['String'];
  orderType: OrderType;
  statusIcon: OrderStatusIcon;
  progressStatusIcon?: Maybe<ProgressStatusIcon>;
  fmsStatus?: Maybe<FmsStatus>;
  isFavorite: Scalars['Boolean'];
  /** The currency used for customer-related monetary amounts. */
  customerCurrency?: Maybe<Scalars['String']>;
  /** The currency used for internal monetary amounts. */
  internalCurrency?: Maybe<Scalars['String']>;
  /**
   * The currency rate for `customerCurrency`. This is intended for display and is not sufficient for currency
   * conversion.
   */
  customerCurrencyRate?: Maybe<Scalars['Float']>;
  /**
   * The currency rate for `internalCurrency`. This is intended for display and is not sufficient for currency
   * conversion.
   */
  internalCurrencyRate?: Maybe<Scalars['Float']>;
  /**
   * The currency factor for `customerCurrency`. Multiply amounts in that currency by this factor to get the amount
   * in NOK.
   */
  customerCurrencyFactor?: Maybe<Scalars['Float']>;
  /**
   * The currency factor for `internalCurrency`. Multiply amounts that currency by this factor to get the amount in
   * NOK.
   */
  internalCurrencyFactor?: Maybe<Scalars['Float']>;
  /** The gross cost price in the currency specified by `internalCurrency`. */
  grossCostPrice?: Maybe<Scalars['Float']>;
  /** The factory's cost price in the currency specified by `internalCurrency`. */
  netFactoryCostPrice?: Maybe<Scalars['Float']>;
  /** The net internal cost price in the currency specified by `internalCurrency`. */
  netInternalCostPrice?: Maybe<Scalars['Float']>;
  /** The net sales cost price in the currency specified by `internalCurrency`. */
  netSalesCostPrice?: Maybe<Scalars['Float']>;
  /** The net customer price in the currency specified by `customerCurrency`. */
  netCustomerPrice?: Maybe<Scalars['Float']>;
  /** The gross margin ratio (in percent) without considering bonus. */
  gmrWithoutBonus?: Maybe<Scalars['Float']>;
  /** The gross margin ratio (in percent) with bonus considered. */
  gmrWithBonus?: Maybe<Scalars['Float']>;
  /** The customer's total bonus in percent. */
  totalBonusRate?: Maybe<Scalars['Float']>;
  /** The customer's total bonus in the currency specified by `customerCurrency`. */
  totalBonusAmount?: Maybe<Scalars['Float']>;
  /** The customer's total discount in percent. */
  totalDiscountRate?: Maybe<Scalars['Float']>;
  /** The customer's total bonus in the currency specified by `customerCurrency`. */
  totalDiscountAmount?: Maybe<Scalars['Float']>;
  /** The order's VAT rate in percent. */
  vatRate?: Maybe<Scalars['Float']>;
  /** The order's VAT rate in the currency specified by `customerCurrency`. */
  vatAmount?: Maybe<Scalars['Float']>;
  vatCode?: Maybe<Scalars['Int']>;
  vatCountry?: Maybe<Scalars['CountryCode']>;
  vatType?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
  paymentTerms?: Maybe<Scalars['String']>;
  priceList?: Maybe<Scalars['String']>;
  priceType?: Maybe<PriceType>;
  /** The markup in percent used for this order. */
  markupRate?: Maybe<Scalars['Float']>;
  /**
   * Assigned discounts (in percent) used for individual products in this order. This is an expensive field; do not
   * load it in a list of orders.
   */
  productDiscounts?: Maybe<Array<OrderProductDiscount>>;
  /**
   * Assigned target gross margin ratios (in percent) used for individual products in this order. This is an
   * expensive field; do not load it in a list of orders.
   */
  productGmrs?: Maybe<Array<OrderProductGmr>>;
  salesPerson: User;
  /** The user who has locked this order for editing. */
  lockedBy?: Maybe<User>;
  /** Returns the order's customer. For quotes, this returns the first item in `quoteCustomers`. */
  customer?: Maybe<Customer>;
  /**
   * Returns all customers for a quote. Returns `null` for non-quote orders. This is much more performant than going
   * via `frontPages`, and can safely be included in a list of orders. However, it only includes customers
   * in NorDan's customer database, not ad-hoc customers used only for this quote.
   */
  quoteCustomers?: Maybe<Array<Customer>>;
  customerOwner?: Maybe<Customer>;
  customerReference?: Maybe<Scalars['String']>;
  customerProject?: Maybe<Scalars['String']>;
  customerOrder?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  /** The order's follow-up date. Only relevant for quotes. */
  followUpDate?: Maybe<Scalars['LocalDate']>;
  /** The order's correction deadline. Not relevant for quotes. */
  correctionDeadline?: Maybe<Scalars['LocalDate']>;
  /** Returns the order's order lines. This is an expensive field; do not load it in a list of orders. */
  orderLines?: Maybe<OrderOrderLinesConnection>;
  /** Returns the order's documents. This is an expensive field; do not load it in a list of orders. */
  documents: Array<OrderDocument>;
  /** Returns the order's front pages. This is an expensive field; do not load it in a list of orders. */
  frontPages: Array<OrderFrontPage>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type OrderOrderLinesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};

export type OrderDocumentsArgs = {
  filter: OrderDocumentFilterInput;
};

export type OrderDocument = {
  __typename?: 'OrderDocument';
  id: Scalars['ID'];
  type: OrderDocumentType;
  printType?: Maybe<OrderDocumentPrintType>;
  category?: Maybe<Scalars['String']>;
  /** The document's extension. Lower-case and without the leading dot, e.g. `pdf` or `jpg`. */
  extension?: Maybe<Scalars['String']>;
  numBytes?: Maybe<Scalars['Long']>;
  /** A two-letter language code identifying the document's language. */
  language?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Decimal']>;
  description?: Maybe<Scalars['String']>;
  owner?: Maybe<OrderDocumentOwner>;
  frontPage?: Maybe<OrderFrontPage>;
  orderType: OrderType;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<User>;
  downloadUrl: Scalars['URL'];
};

export type OrderFrontPage = {
  __typename?: 'OrderFrontPage';
  id: Scalars['ID'];
  customer?: Maybe<Customer>;
};

export type OrderLine = {
  __typename?: 'OrderLine';
  id: Scalars['ID'];
  lineNumber: Scalars['Int'];
  quantity: Scalars['Int'];
  productCode: Scalars['String'];
  /** The gross unit cost price in the currency specified by the order's `internalCurrency` */
  grossCostPrice?: Maybe<Scalars['Float']>;
  /** The discount in percent used to calculate `netFactoryCostPrice` from `grossCostPrice`. */
  factoryDiscountRate?: Maybe<Scalars['Float']>;
  /** The net factory unit cost price in the currency specified by the order's `internalCurrency`. */
  netFactoryCostPrice?: Maybe<Scalars['Float']>;
  /** The discount in percent used to calculate `netInternalCostPrice` from `grossCostPrice`. */
  internalDiscountRate?: Maybe<Scalars['Float']>;
  /** The net internal unit cost price in the currency specified by the order's `internalCurrency`. */
  netInternalCostPrice?: Maybe<Scalars['Float']>;
  /** The discount in percent used to calculate `netSalesCostPrice` from `grossCostPrice`. */
  salesDiscountRate?: Maybe<Scalars['Float']>;
  /** The net sales unit cost price in the currency specified by the order's `internalCurrency`. */
  netSalesCostPrice?: Maybe<Scalars['Float']>;
  /** The customer's discount in percent. */
  customerDiscountRate?: Maybe<Scalars['Float']>;
  /** The gross unit customer price in the currency specified by the order's `customerCurrency`. */
  grossCustomerPrice: Scalars['Float'];
  /**
   * The net unit customer price, not including the order's total discount or bonus, in the currency specified by the
   * order's `customerCurrency`.
   */
  netCustomerPrice: Scalars['Float'];
  /**
   * The net unit customer price, including the order's total discount but not the bonus, in the currency specified
   * by the order's `customerCurrency`.
   */
  netCustomerPriceAfterOrderTotalDiscount: Scalars['Float'];
  /**
   * The net unit customer price, including the order's total discount and bonus, in the currency specified by the
   * order's `customerCurrency`.
   */
  netCustomerPriceAfterOrderTotalDiscountAndBonus?: Maybe<Scalars['Float']>;
  /** The customer's total discount in percent. */
  totalDiscountRate?: Maybe<Scalars['Float']>;
  /** The customer's total unit discount in the currency specified by the order's `customerCurrency`. */
  totalDiscountAmount?: Maybe<Scalars['Float']>;
  /** The customer's total bonus in percent. */
  totalBonusRate?: Maybe<Scalars['Float']>;
  /** The customer's total unit bonus in the currency specified by the order's `customerCurrency`. */
  totalBonusAmount?: Maybe<Scalars['Float']>;
  /** The gross margin ratio (in percent) without considering bonus. */
  gmrWithoutBonus?: Maybe<Scalars['Float']>;
  /** The gross margin ratio (in percent) with bonus considered. */
  gmrWithBonus?: Maybe<Scalars['Float']>;
};

/** A connection to a list of items. */
export type OrderOrderLinesConnection = {
  __typename?: 'OrderOrderLinesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<OrderOrderLinesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<OrderLine>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type OrderOrderLinesEdge = {
  __typename?: 'OrderOrderLinesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: OrderLine;
};

/** Represents discount information for a product in an order. */
export type OrderProductDiscount = {
  __typename?: 'OrderProductDiscount';
  productCode: Scalars['String'];
  discountRate: Scalars['Float'];
  averageGmrWithoutBonus?: Maybe<Scalars['Float']>;
  averageGmrWithBonus?: Maybe<Scalars['Float']>;
};

/** Represents GMR information for a product in an order. */
export type OrderProductGmr = {
  __typename?: 'OrderProductGmr';
  productCode: Scalars['String'];
  gmrWithoutBonus: Scalars['Float'];
  averageGmrWithBonus?: Maybe<Scalars['Float']>;
};

export type OrderUpdatedPayload = {
  __typename?: 'OrderUpdatedPayload';
  order?: Maybe<Order>;
};

/** A connection to a list of items. */
export type OrdersConnection = {
  __typename?: 'OrdersConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<OrdersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Order>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type OrdersEdge = {
  __typename?: 'OrdersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Order;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

export type SetFavoriteIndexPayload = {
  __typename?: 'SetFavoriteIndexPayload';
  favorite?: Maybe<Favorite>;
  errors?: Maybe<Array<SetFavoriteIndexError>>;
  query: Query;
};

export type SetNewsfeedArticleIsReadPayload = {
  __typename?: 'SetNewsfeedArticleIsReadPayload';
  article?: Maybe<NewsfeedArticle>;
  errors?: Maybe<Array<SetNewsfeedArticleIsReadError>>;
  query: Query;
};

export type SetStatusMessageIsReadPayload = {
  __typename?: 'SetStatusMessageIsReadPayload';
  errors?: Maybe<Array<SetStatusMessageIsReadError>>;
  query: Query;
};

export type StatusMessage = {
  __typename?: 'StatusMessage';
  id: Scalars['ID'];
  /** The message text. Plain text without newlines. */
  text: Scalars['String'];
  audience: StatusMessageAudience;
  /**
   * The expiration time of the status message. If `null`, the status message was sent only to users signed in at the
   * moment of publishing and is considered expired as of `createdAt`.
   */
  showUntil?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  /** Whether the current user has marked the status message as read. */
  isRead: Scalars['Boolean'];
};

/** Indicates that no status message was found with the specified ID. */
export type StatusMessageNotFoundError = Error & {
  __typename?: 'StatusMessageNotFoundError';
  message: Scalars['String'];
  /** An identifier that can be used to search for this error in server logs. */
  correlationId?: Maybe<Scalars['String']>;
};

export type StatusMessagePublishedPayload = {
  __typename?: 'StatusMessagePublishedPayload';
  /** The published status message. This is `null` if the user does not have access to it. */
  message?: Maybe<StatusMessage>;
};

export type Subscription = {
  __typename?: 'Subscription';
  statusMessagePublished: StatusMessagePublishedPayload;
  orderUpdated: OrderUpdatedPayload;
  capacityUpdated: CapacityUpdatedPayload;
};

export type SubscriptionOrderUpdatedArgs = {
  orderId: Scalars['ID'];
};

export type TestTriggerCapacityUpdatedPayload = {
  __typename?: 'TestTriggerCapacityUpdatedPayload';
  query: Query;
};

export type TestTriggerOrderUpdatedPayload = {
  __typename?: 'TestTriggerOrderUpdatedPayload';
  query: Query;
};

export type User = Node & {
  __typename?: 'User';
  id: Scalars['ID'];
  username: Scalars['String'];
  firstName: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['MobileNumber']>;
  photoUrl?: Maybe<Scalars['URL']>;
  customer?: Maybe<Customer>;
  /**
   * The user's favorites. Returns null if the requesting user is not authorized to view this user's favorites. Users
   * are always allowed to view their own favorites.
   */
  favorites?: Maybe<Array<Favorite>>;
  /**
   * The user's access. Returns null if the requesting user is not authorized to view this user's access. Users are
   * always allowed to view their own access.
   */
  access?: Maybe<UserAccess>;
  email: Scalars['String'];
  role: Role;
};

export type UserAccess = {
  __typename?: 'UserAccess';
  capacity: UserCapacityAccess;
  order: UserOrderAccess;
  statusMessage: UserStatusMessageAccess;
};

export type UserCapacityAccess = {
  __typename?: 'UserCapacityAccess';
  /** Whether the user has access to the capacity subsystem at all. */
  hasAccess: Scalars['Boolean'];
  /** The codes for the factories for which the user can access capacity data. */
  factories: Array<Scalars['String']>;
  /** The categories for which the user can access capacity data. */
  categories: Array<CapacityCategory>;
  /** The workstation planning types for which the user can access capacity data. */
  planningTypes: Array<CapacityPlanningType>;
  /** The aggregate values for which the user can access capacity data. */
  aggregateValueTypes: Array<CapacityAggregateValueType>;
  /** Whether the user has access to view order summaries. */
  canViewOrderSummary: Scalars['Boolean'];
};

export type UserOrderAccess = {
  __typename?: 'UserOrderAccess';
  /** The order types the user has read access to. */
  readOrderTypes: Array<OrderType>;
  /** The order types for which the user has write access to non-test orders. */
  writeOrderTypes: Array<OrderType>;
  /** The order types for which the user has write access to test orders. */
  writeTestOrderTypes: Array<OrderType>;
  /** The names of all `Order` fields that the user does not have access to. */
  restrictedOrderFields: Array<Scalars['String']>;
  /** The names of all `OrderLine` fields that the user does not have access to. */
  restrictedOrderLineFields: Array<Scalars['String']>;
};

export type UserStatusMessageAccess = {
  __typename?: 'UserStatusMessageAccess';
  /** Whether the user can manage status messages. */
  canManageStatusMessages: Scalars['Boolean'];
};

/** A connection to a list of items. */
export type UsersConnection = {
  __typename?: 'UsersConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<UsersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<User>>;
};

/** An edge in a connection. */
export type UsersEdge = {
  __typename?: 'UsersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: User;
};

export type WorkstationCapacity = {
  __typename?: 'WorkstationCapacity';
  /** The workstation ID. */
  id: Scalars['ID'];
  /** The workstation's capacity planning type. */
  planningType: CapacityPlanningType;
  /** A list of weekly capacity data for this workstation. */
  weeklyCapacities: Array<WorkstationWeeklyCapacity>;
  /** Aggregate order data for this workstation. */
  orderSummary?: Maybe<CapacityOrderSummary>;
};

export type WorkstationCapacityWeeklyCapacitiesArgs = {
  category: CapacityCategory;
  startYear: Scalars['Int'];
  startWeek: Scalars['Int'];
  numWeeks: Scalars['Int'];
};

export type WorkstationCapacityOrderSummaryArgs = {
  category: CapacityCategory;
  year: Scalars['Int'];
  week: Scalars['Int'];
};

export type WorkstationWeeklyCapacity = {
  __typename?: 'WorkstationWeeklyCapacity';
  /** The year part of the year/week these data are for. */
  year: Scalars['Int'];
  /** The week part of the year/week these data are for. */
  week: Scalars['Int'];
  /** The capacity status for the associated workstation in this week. */
  status: WorkstationCapacityStatus;
  /**
   * The usage in percent for the associated workstation in this week. Note that this could be outside the interval
   * [0, 100]. Returns `null` if there is no value (for example if the factory is closed).
   */
  percentageUsed?: Maybe<Scalars['Float']>;
};

export type CreateFavoriteError =
  | FavoriteItemNotFoundError
  | NoAccessToFavoriteItemError
  | ItemAlreadyFavoriteError;

export type CreateStatusMessageError = NoAccessToManageStatusMessagesError;

export type DeleteFavoriteError = FavoriteNotFoundError;

export type DeleteStatusMessageError =
  | NoAccessToManageStatusMessagesError
  | StatusMessageNotFoundError;

export type FavoriteItem = Order | NewsfeedArticle;

export type OrderDocumentOwner = Order | Customer;

export type SetFavoriteIndexError = FavoriteNotFoundError;

export type SetNewsfeedArticleIsReadError = NewsfeedArticleNotFoundError;

export type SetStatusMessageIsReadError = StatusMessageNotFoundError;

export type CapacityOrderSummaryOrderFilterInput = {
  /** Only return orders with this capacity order type. */
  capacityOrderType?: Maybe<CapacityOrderType>;
  /** Filter to apply from the top-level `orders` field. */
  orderFilter?: Maybe<OrderFilterInput>;
};

export type CreateFavoriteInput = {
  itemType: FavoriteItemType;
  itemId: Scalars['ID'];
  index?: Scalars['Int'];
};

export type CreateStatusMessageInput = {
  /** The message text. Plain text without newlines. Must not be empty or contain only whitespace. */
  text: Scalars['String'];
  audience: StatusMessageAudience;
  /**
   * The expiration time of the status message. If `null`, the status message will be sent only to users signed in at
   * the moment of publishing.
   */
  showUntil?: Maybe<Scalars['DateTime']>;
};

export type CustomerFilterInput = {
  /** Only return customers matching this search term. */
  searchTerm?: Maybe<Scalars['String']>;
};

export type CustomerSortTermInput = {
  order?: SortOrder;
  nulls?: Maybe<NullOrder>;
  key: CustomerSortKey;
};

/** Filter for DateTime values. If multiple fields are used, they will be combined using AND. */
export type DateTimeFilterInput = {
  /** Greater than: > */
  gt?: Maybe<Scalars['DateTime']>;
  /** Greater than or equal to: >= */
  gte?: Maybe<Scalars['DateTime']>;
  /** Less than: < */
  lt?: Maybe<Scalars['DateTime']>;
  /** Less than or equal to: <= */
  lte?: Maybe<Scalars['DateTime']>;
};

export type DeleteFavoriteInput = {
  favoriteId: Scalars['ID'];
};

export type DeleteStatusMessageInput = {
  messageId: Scalars['ID'];
};

/** Filter for Float values. If multiple fields are used, they will be combined using AND. */
export type FloatFilterInput = {
  /** Greater than: > */
  gt?: Maybe<Scalars['Float']>;
  /** Greater than or equal to: >= */
  gte?: Maybe<Scalars['Float']>;
  /** Less than: < */
  lt?: Maybe<Scalars['Float']>;
  /** Less than or equal to: <= */
  lte?: Maybe<Scalars['Float']>;
};

export type NewsfeedArticleFilterInput = {
  /** Only return articles matching all of these search terms. */
  searchTerms?: Maybe<Array<Scalars['String']>>;
};

export type NewsfeedArticleSortTermInput = {
  order?: SortOrder;
  nulls?: Maybe<NullOrder>;
  key: NewsfeedArticleSortKey;
};

export type OrderDocumentFilterInput = {
  /** Whether to return only the most relevant documents. */
  currentDocuments?: Scalars['Boolean'];
  /** Whether to include linked documents. */
  includeLinked?: Scalars['Boolean'];
  /** Whether to include agreement documents. */
  includeAgreement?: Scalars['Boolean'];
};

/**
 * Order filter. If a filter is used that corresponds to a field in the user's `access.order.restrictedOrderFields`,
 * the behavior is undefined (the filter may be ignored, or no results may be returned).
 */
export type OrderFilterInput = {
  /** Only return orders with this `orderType`. */
  orderType?: Maybe<OrderType>;
  /**
   * If `true`, only return the orders most relevant to the current user. This is primarily intended to be used on
   * its own.
   */
  currentOrders?: Scalars['Boolean'];
  /** Whether to search for archived or non-archived orders. */
  isArchived?: Maybe<Scalars['Boolean']>;
  /** Only return orders matching all of these search terms. */
  searchTerms?: Maybe<Array<Scalars['String']>>;
  /** Only return the order with this exact `orderNumber`. */
  orderNumber?: Maybe<Scalars['String']>;
  /**
   * Only return orders where `netCustomerPrice` matches this filter. If the order's `customerCurrency` is not `NOK`,
   * this filter applies to the corresponding NOK value based on `customerCurrencyFactor`.
   */
  netCustomerPriceNOK?: Maybe<FloatFilterInput>;
  /** Only return orders where `gmrWithoutBonus` matches this filter. */
  gmrWithoutBonus?: Maybe<FloatFilterInput>;
  /** Only return orders where `gmrWithBonus` matches this filter. */
  gmrWithBonus?: Maybe<FloatFilterInput>;
  /** Only return orders where `updatedAt` matches this filter. */
  updatedAt?: Maybe<DateTimeFilterInput>;
  /** Only return orders with the specified `isFavorite` value. */
  isFavorite?: Maybe<Scalars['Boolean']>;
  /** Only return orders where `customerOwner` is a customer whose `id` is one of these values. */
  customerOwners?: Maybe<Array<Scalars['ID']>>;
  /** Only return orders where `customer` is a customer whose `id` is one of these values. */
  customers?: Maybe<Array<Scalars['ID']>>;
  /** Only return orders where `salesPerson` is a user whose `id` is one of these values. */
  salesPersons?: Maybe<Array<Scalars['ID']>>;
};

export type OrderSortTermInput = {
  order?: SortOrder;
  nulls?: Maybe<NullOrder>;
  key: OrderSortKey;
};

export type SetFavoriteIndexInput = {
  favoriteId: Scalars['ID'];
  index: Scalars['Int'];
};

export type SetNewsfeedArticleIsReadInput = {
  articleId: Scalars['ID'];
  isRead: Scalars['Boolean'];
};

export type SetStatusMessageIsReadInput = {
  messageId: Scalars['ID'];
  isRead: Scalars['Boolean'];
};

export type StatusMessageFilterInput = {
  /** If `true`, return both read and unread messages. This is primarily useful during message management. */
  includeRead?: Scalars['Boolean'];
  /** If `true`, return both expired and active messages. This is primarily useful during message management. */
  includeExpired?: Scalars['Boolean'];
};

export type TestTriggerOrderUpdatedInput = {
  orderId: Scalars['ID'];
};

export type UserFilterInput = {
  /** Only return users matching this search term. */
  searchTerm?: Maybe<Scalars['String']>;
};

export type UserSortTermInput = {
  order?: SortOrder;
  nulls?: Maybe<NullOrder>;
  key: UserSortKey;
};

/** Defines when a policy shall be executed. */
export enum ApplyPolicy {
  /** Before the resolver was executed. */
  BeforeResolver = 'BEFORE_RESOLVER',
  /** After the resolver was executed. */
  AfterResolver = 'AFTER_RESOLVER',
  /** The policy is applied in the validation step before the execution. */
  Validation = 'VALIDATION'
}

/** Specifies a type of aggregate value used in capacity data. */
export enum CapacityAggregateValueType {
  /** The associated value is the number of orders. The value is guaranteed to not contain a non-zero fractional part. */
  OrderCount = 'ORDER_COUNT',
  /** The associated value is the cost price in NOK. */
  CostPrice = 'COST_PRICE',
  /** The associated value is the customer price in NOK. */
  CustomerPrice = 'CUSTOMER_PRICE',
  /** The associated value is the gross margin in percent. */
  GrossMargin = 'GROSS_MARGIN'
}

export enum CapacityCategory {
  Total = 'TOTAL',
  Cat1 = 'CAT1',
  Cat2 = 'CAT2',
  Cat3 = 'CAT3',
  Cat4 = 'CAT4'
}

export enum CapacityOrderType {
  Incomplete = 'INCOMPLETE',
  Control = 'CONTROL',
  Final = 'FINAL'
}

export enum CapacityPlanningType {
  Production = 'PRODUCTION',
  Sales = 'SALES'
}

export enum CustomerSortKey {
  /**
   * Sort the results by relevance to the search term. This is used only if the `searchTerm` filter is provided.
   * Otherwise, it is ignored.
   */
  Relevance = 'RELEVANCE',
  CustomerNumber = 'CUSTOMER_NUMBER',
  Name = 'NAME'
}

export enum FavoriteItemType {
  Order = 'ORDER',
  NewsfeedArticle = 'NEWSFEED_ARTICLE'
}

export enum FmsStatus {
  PendingFms = 'PENDING_FMS',
  FmsOwned = 'FMS_OWNED',
  PendingRelease = 'PENDING_RELEASE',
  Released = 'RELEASED',
  ReleasedLight = 'RELEASED_LIGHT'
}

export enum NewsfeedArticleSortKey {
  /**
   * Sort the results by relevance to the search term. This is used only if the `searchTerm` filter is provided.
   * Otherwise, it is ignored.
   */
  Relevance = 'RELEVANCE',
  ValidFrom = 'VALID_FROM'
}

/** Specifies where null values will appear in a sort operation. */
export enum NullOrder {
  /** All null values appear before all non-null values. */
  First = 'FIRST',
  /** All null values appear after all non-null values. */
  Last = 'LAST'
}

export enum OrderDocumentPrintType {
  SummaryChecklist = 'SUMMARY_CHECKLIST',
  QuoteFinal = 'QUOTE_FINAL',
  QuoteControl = 'QUOTE_CONTROL',
  OrderFinal = 'ORDER_FINAL',
  OrderControl = 'ORDER_CONTROL',
  ProductionAppendix = 'PRODUCTION_APPENDIX',
  ScheduleOfQuantities = 'SCHEDULE_OF_QUANTITIES',
  PalletList = 'PALLET_LIST',
  ExportExcel = 'EXPORT_EXCEL',
  ExportCsv = 'EXPORT_CSV',
  ExportXml = 'EXPORT_XML',
  Energy = 'ENERGY',
  EnergyXls = 'ENERGY_XLS',
  WebOrderPrintout = 'WEB_ORDER_PRINTOUT',
  Fdv = 'FDV',
  Epd = 'EPD',
  Epdxls = 'EPDXLS'
}

export enum OrderDocumentType {
  Print = 'PRINT',
  Production = 'PRODUCTION',
  Internal = 'INTERNAL',
  System = 'SYSTEM',
  Customer = 'CUSTOMER'
}

/**
 * Order sort keys. If a sort key is used that corresponds to a field in the user's
 * `access.order.restrictedOrderFields`, the key will be ignored.
 */
export enum OrderSortKey {
  /**
   * Sort the results by relevance to the search term. This is used only if the `searchTerm` filter is provided.
   * Otherwise, it is ignored.
   */
  Relevance = 'RELEVANCE',
  OrderNumber = 'ORDER_NUMBER',
  /**
   * If the order's `customerCurrency` is not `NOK`, this sort key applies to the corresponding NOK value based on
   * `customerCurrencyFactor`.
   */
  NetCustomerPriceNok = 'NET_CUSTOMER_PRICE_NOK',
  GmrWithoutBonus = 'GMR_WITHOUT_BONUS',
  GmrWithBonus = 'GMR_WITH_BONUS',
  UpdatedAt = 'UPDATED_AT',
  IsFavorite = 'IS_FAVORITE',
  CustomerOwnerNumber = 'CUSTOMER_OWNER_NUMBER',
  CustomerOwnerName = 'CUSTOMER_OWNER_NAME',
  CustomerNumber = 'CUSTOMER_NUMBER',
  CustomerName = 'CUSTOMER_NAME',
  SalesPersonFirstName = 'SALES_PERSON_FIRST_NAME',
  SalesPersonLastName = 'SALES_PERSON_LAST_NAME'
}

export enum OrderStatusIcon {
  /** The order is archived. */
  Archived = 'ARCHIVED',
  /** The order is locked for editing by another user, indicated by the `Order.lockedBy` field. */
  LockedByOtherUser = 'LOCKED_BY_OTHER_USER',
  /** The order is locked for editing by the current user. */
  OpenedForEditing = 'OPENED_FOR_EDITING',
  /** The order has errors. */
  Error = 'ERROR',
  /** The order is incomplete. */
  Ongoing = 'ONGOING',
  /** The order is soft-deleted (all lines are set to product code ZZ). */
  Deleted = 'DELETED',
  /**
   * For quotes: The order is after its follow-up date indicated by the `Order.followUpDate` field.
   *
   * For non-quote orders: A control order has been printed, and the order is after its correction deadline
   * indicated by the `Order.correctionDeadline` field.
   */
  PostDueDate = 'POST_DUE_DATE',
  /**
   * For quotes: The order is before its follow-up date indicated by the `Order.followUpDate` field.
   *
   * For non-quote orders: A control order has been printed, and the order is before its correction deadline
   * indicated by the `Order.correctionDeadline` field.
   */
  PreDueDate = 'PRE_DUE_DATE',
  /** The order is completed. */
  Done = 'DONE'
}

export enum OrderType {
  Order = 'ORDER',
  Quote = 'QUOTE',
  StockDelivery = 'STOCK_DELIVERY',
  Credit = 'CREDIT',
  CustomerStock = 'CUSTOMER_STOCK'
}

export enum PriceType {
  Framework = 'FRAMEWORK',
  Project = 'PROJECT',
  FrameworkPlus = 'FRAMEWORK_PLUS',
  CostPricePlus = 'COST_PRICE_PLUS'
}

export enum ProgressStatusIcon {
  ProductionStarted = 'PRODUCTION_STARTED',
  ProductionFinished = 'PRODUCTION_FINISHED',
  PackingFinished = 'PACKING_FINISHED',
  Delivered = 'DELIVERED',
  Paid = 'PAID'
}

/** Specifies the order of values in a sort operation. */
export enum SortOrder {
  /** Values are sorted in ascending order (from lowest to highest). */
  Asc = 'ASC',
  /** Values are sorted in descending order (from highest to lowest). */
  Desc = 'DESC'
}

export enum StatusMessageAudience {
  AllUsers = 'ALL_USERS',
  InternalUsers = 'INTERNAL_USERS'
}

export enum UserSortKey {
  /**
   * Sort the results by relevance to the search term. This is used only if the `searchTerm` filter is provided.
   * Otherwise, it is ignored.
   */
  Relevance = 'RELEVANCE',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME'
}

export enum WorkstationCapacityStatus {
  FactoryClosed = 'FACTORY_CLOSED',
  AvailableCapacity = 'AVAILABLE_CAPACITY',
  LoanAvailable = 'LOAN_AVAILABLE',
  OverflowProduction = 'OVERFLOW_PRODUCTION',
  OverflowSales = 'OVERFLOW_SALES'
}

export enum Role {
  User = 'USER',
  Admin = 'ADMIN'
}

export type Node = {
  id: Scalars['ID'];
};

export type SearchResult = User | Chat | ChatMessage;

export type Chat = Node & {
  __typename?: 'Chat';
  id: Scalars['ID'];
  users: Array<User>;
  messages: Array<ChatMessage>;
};

export type ChatMessage = Node & {
  __typename?: 'ChatMessage';
  id: Scalars['ID'];
  content: Scalars['String'];
  time: Scalars['Date'];
  user: User;
};

export type FindUserQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type FindUserQuery = { __typename?: 'Query' } & {
  user?: Maybe<{ __typename?: 'User' } & UserFieldsFragment>;
};

export type UserFieldsFragment = { __typename?: 'User' } & Pick<User, 'id' | 'username' | 'role'>;
