import gql from 'graphql-tag';
import { STATUS_MESSAGE_FIELDS } from './fragments';

export const CURRENT_USER_QUERY_STRING = `
  query CurrentUser {
    me {
      id
      firstName
      lastName
      mobileNumber
      photoUrl
      username
      access {
        capacity {
          canViewOrderSummary
          aggregateValueTypes
          hasAccess
          categories
          factories
          planningTypes
        }
        order {
          readOrderTypes
          writeOrderTypes
          writeTestOrderTypes
          restrictedOrderFields
          restrictedOrderLineFields
        }
        statusMessage {
          canManageStatusMessages
        }
      }
      favorites {
        id
        index
        createdAt
        item {
        ... on NewsfeedArticle {
            id
            isRestricted
            validFrom
            departmentCode
            text
            title
            previewUrl
          }
        }
      }
    }
  }
`;

export const CURRENT_USER_QUERY = gql`
  ${CURRENT_USER_QUERY_STRING}
`;

export const STATUS_MESSAGE_QUERY = gql`
  query StatusMessage($filter: StatusMessageFilterInput) {
    statusMessages(filter: $filter) {
      ...StatusMessageFields
    }
  }
  ${STATUS_MESSAGE_FIELDS}
`;
