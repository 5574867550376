import gql from 'graphql-tag';
import { CUSTOMER_FIELDS, ORDER_FIELDS, ORDER_DOCUMENT_FIELDS, USER_FIELDS } from './fragments';
import { PAGE_INFO_FIELDS } from '@/gql/fragments';
import { NEWSFEED_ARTICLE_FIELDS } from '@/views/news/gql/fragments';
export const GET_USER_FAVORITES = gql`
  query UserFavorites {
    me {
      favorites {
        __typename
        id
        item {
          ...OrderFields
          ...NewsfeedArticleFields
        }
      }
    }
  }
  ${CUSTOMER_FIELDS}
  ${ORDER_FIELDS}
  ${NEWSFEED_ARTICLE_FIELDS}
`;

export const ORDER_COUNT_QUERY = gql`
  query OrderCount($filters: OrderFilterInput!, $first: Int, $after: String) {
    orders(filter: $filters, first: $first, after: $after) {
      ... on OrdersConnection {
        totalCount
      }
    }
  }
`;

export const GET_ORDER_QUERY = gql`
  query OrderById($id: ID!, $documentFilters: OrderDocumentFilterInput!) {
    order(id: $id) {
      ...OrderFields
      documents(filter: $documentFilters) {
        ...OrderDocumentFields
      }
    }
  }
  ${ORDER_FIELDS}
  ${ORDER_DOCUMENT_FIELDS}
`;

export const SEARCH_ORDER_QUERY = gql`
  query OrderSearch(
    $filters: OrderFilterInput!
    $sort: [OrderSortTermInput!]
    $first: Int
    $after: String
  ) {
    orders(filter: $filters, sort: $sort, first: $first, after: $after) {
      ... on OrdersConnection {
        nodes {
          ...OrderFields
        }
        pageInfo {
          ...PageInfoFields
        }
      }
    }
  }
  ${ORDER_FIELDS}
  ${PAGE_INFO_FIELDS}
`;

export const SEARCH_CUSTOMERS_QUERY = gql`
  query CustomerSearch($searchTerm: String!, $first: Int) {
    customers(filter: { searchTerm: $searchTerm }, first: $first) {
      nodes {
        ...CustomerFields
      }
    }
  }
  ${CUSTOMER_FIELDS}
`;

export const SEARCH_USERS_QUERY = gql`
  query UserSearch($searchTerm: String!, $first: Int) {
    users(filter: { searchTerm: $searchTerm }, first: $first) {
      nodes {
        ...UserFields
        customer {
          ...CustomerFields
        }
      }
    }
  }
  ${CUSTOMER_FIELDS}
  ${USER_FIELDS}
`;
